import { DirectUpload } from '@rails/activestorage'

export class FileUpload {
  constructor (attachment, element) {
    this.attachment = attachment
    this.element = element
    this.directUpload = new DirectUpload(
      attachment.file,
      this.directUploadUrl,
      this
    )
  }

  start () {
    this.directUpload.create(this.directUploadDidComplete.bind(this))
  }

  directUploadWillStoreFileWithXHR (xhr) {
    xhr.upload.addEventListener('progress', event => {
      const progress = (event.loaded / event.total) * 100
      this.attachment.setUploadProgress(progress)
    })
  }

  directUploadDidComplete (error, attributes) {
    if (error) {
      throw new Error(`Direct Upload Failed: ${error}`)
    }

    this.attachment.setAttributes({
      sgid: attributes.attachable_sgid,
      url: this.createBlobUrl(attributes.signed_id, attributes.filename)
    })

    // add field to save embed to model
    const hiddenField = document.createElement('input')
    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('value', attributes.signed_id)
    hiddenField.name = 'landing_page[embeds][]'
    document.querySelector('form').appendChild(hiddenField)
  }

  createBlobUrl (signedId, filename) {
    return this.blobUrlTemplate
      .replace(':signed_id', signedId)
      .replace(':filename', filename)
  }

  get directUploadUrl () {
    return this.element.dataset.directUploadUrl
  }

  get blobUrlTemplate () {
    return this.element.dataset.blobUrlTemplate
  }
}
