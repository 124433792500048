import { FileUpload } from './fileUpload'

window.addEventListener('trix-attachment-add', event => {
  const { attachment, target } = event

  if (attachment.file) {
    const upload = new FileUpload(attachment, target)
    upload.start()
  }
})
